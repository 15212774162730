/*
100 thin
200 light
300 semilight
400 regular
500 medium
600 semibold
700 bold
800 extrabold
900 black
*/

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-regular.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/files/assets/dist/fonts/montserrat/montserrat-v12-latin-700.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* vistasansot-light */
@font-face {
    font-family: 'VistaSansOT';
    font-style: normal;
    font-weight: 300;
    src: url('/files/assets/dist/fonts/vistasansot/VistaSansOT-Light.otf') format('opentype');
}

@font-face {
    font-family: 'VistaSansOT';
    font-style: italic;
    font-weight: 300;
    src: url('/files/assets/dist/fonts/vistasansot/VistaSansOT-LightItalic.otf') format('opentype');
}

/* vistasansot-book */
@font-face {
    font-family: 'VistaSansOT';
    font-style: normal;
    font-weight: 390;
    src: url('/files/assets/dist/fonts/vistasansot/VistaSansOT-Book.otf') format('opentype');
}