/** Switches
****************************************************************/
$enable-rounded: false;
// $border-radius: 1rem;
// $enable-gradients: true;

/** Colors
****************************************************************/
$body-bg: #fff;
$body-color: #000;
$theme-colors: (
'primary': #E5BA32,
'secondary': #000,
'light-gray': #F6F6F6
);

/** Margins
****************************************************************/
$default-margin: 2.8rem;

/** Fonts
****************************************************************/
$font-family-sans-serif: 'VistaSansOT', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-serif: 'Montserrat', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-size-base: 1.8rem;
$font-weight-base: 300;
// $font-weight-bold: 600;

/** Forms
****************************************************************/
// $btn-padding-x: 30px;
// $input-focus-box-shadow: none;
// $input-focus-border-color: map-get($theme-colors, 'primary');

/** Navbar
****************************************************************/
$navbar-padding-y: 24px;
$navbar-padding-x: 16px;
$navbar-nav-link-padding-x: 9px;
$navbar-light-color: $body-color;
$navbar-light-hover-color: map-get($map: $theme-colors, $key: 'primary');
$navbar-light-active-color: map-get($map: $theme-colors, $key: 'primary');

/** Pagination
****************************************************************/
$pagination-padding-y: 8px;
$pagination-padding-x: 16px;

/** Slider
****************************************************************/
$slick-loader-path: '../../dist/img/';
$slick-font-path: '../../dist/fonts/slick/';

/** Font Awesome
****************************************************************/
$fa-font-path: '../../dist/fonts/fontawesome/';

/** Outline
****************************************************************/
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
    // box-shadow: 0 0 0 0 rgba(0,123,255,0);
}
