@import "_variables.scss";

@import "_fonts.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";

/**
Basics
*************************************************************/
html {
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}

body {
    line-height: $default-margin;

    background-color: #fff;
    // -webkit-font-smoothing: antialiased;
    // -webkit-text-stroke: 0.45px;
    // text-rendering: optimizeLegibility;
}

.invisible {
    display: none;
    visibility: hidden;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.container {
    .mod_article {
        margin-bottom: 3.6rem;
    }
}

.text-left {
    h1,
    h2,
    h3 {
        text-align: left !important;
    }
}

@include media-breakpoint-down(lg) {
    .text-right {
        text-align: left !important;
    }
}

/**
Navbar
*************************************************************/

$navbar-animation: 1s ease;

.navbar {
    border-bottom: 4px solid map-get($map: $theme-colors, $key: "primary");

    transition: padding $navbar-animation;

    .nav-item,
    .dropdown-item {
        font-size: 1.6rem;
        font-weight: 300;

        text-transform: uppercase;
    }

    .dropdown-item:hover {
        background-color: map-get($map: $theme-colors, $key: "primary");
        color: #fff;
    }

    .dropdown-menu {
        padding: 0;
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-brand {
        width: 161px;
        height: auto;
        transition: margin-top $navbar-animation,
            margin-bottom $navbar-animation;

        #underline {
            transition: opacity $navbar-animation;
            opacity: 1;
        }
    }

    &.scrolled {
        padding: 5px $navbar-padding-y;

        .navbar-brand {
            margin-top: 5px;
            margin-bottom: -22px;

            #underline {
                opacity: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 5px $navbar-padding-y;

        .navbar-brand {
            margin-top: 5px;
            margin-bottom: -22px;

            #underline {
                opacity: 0;
            }
        }

        ul.navbar-nav {
            margin: 25px 0;
        }
    }

    @include media-breakpoint-down(md) {
        .dropdown-menu {
            border: none;
        }

        .dropdown-item:hover,
        .dropdown-item.active,
        .dropdown-item:active {
            background-color: #fff;
            color: map-get($map: $theme-colors, $key: "primary");
        }
    }
}

.navbar-light .navbar-nav .trail > .nav-link,
.navbar-light .navbar-nav .nav-link.trail {
    color: map-get($map: $theme-colors, $key: "primary");
}

/**
Header
*************************************************************/

#header {
    font-size: 2.8rem;
    line-height: 3.5rem;

    color: #fff;
    text-align: center;

    margin-top: 100px;

    width: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    // background-attachment: fixed;

    background-size: cover;
    height: 750px;

    @include media-breakpoint-down(lg) {
        height: 30vh;
        margin-top: 72px;
    }

    // @include media-breakpoint-down(lg) {
    //     margin-top: 72px;
    // }

    // @media screen and (min-height: 600px) {
    //     min-height: 376px;
    // }

    // @media screen and (min-height: 800px) {
    //     min-height: 450px;
    // }

    // @media screen and (min-height: 1277px) {

    // }

    .image_container {
        border-bottom: 1px solid #fff;

        @media screen and (min-height: 600px) {
            margin-bottom: 30px;
            padding-bottom: 30px;
        }

        @media screen and (min-height: 875px) {
            margin-bottom: 60px;
            padding-bottom: 60px;
        }
    }
}

body.index {
    #header {
        background-size: auto 100vh;
        height: 100vh;
        margin-top: 0;

        // @media screen and (min-height: 600px) {
        //     min-height: 420px;
        // }

        // @media screen and (min-height: 800px) {
        //     min-height: 576px;
        // }

        // @media screen and (min-height: 1277px) {
        //     min-height: 1152px;
        // }
    }
}

// @include media-breakpoint-down(lg) {
//     body.index {
//         #header {
//             min-height: 400px;
//         }
//     }
// }

/**
Content
*************************************************************/

// a[x-apple-data-detectors] {
//     color: inherit !important;
//     text-decoration: none !important;
//     font-size: inherit !important;
//     font-family: inherit !important;
//     font-weight: inherit !important;
//     line-height: inherit !important;
// }

h1,
h2,
h3 {
    font-family: $font-family-serif;
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 4.8rem;

    margin: ($default-margin * 2) 0;

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

@include media-breakpoint-down(lg) {
    h1 {
        font-size: 2rem;
        line-height: 3.6rem;
        margin: $default-margin 0;
    }
}

h2 {
    margin-top: 0;
    text-align: left;
    margin-bottom: $default-margin;
    font-size: 1.8rem;
    line-height: $default-margin;
}

h3 {
    line-height: 2.6rem;
    font-size: 1.8rem;
    margin: ($default-margin / 2) 0;
    text-align: left;
}

b,
strong {
    // font-size: 1.8rem;
    font-weight: bold;

    // text-transform: uppercase;
    // font-family: $font-family-serif;
}

i,
em {
    font-weight: 300;
}

p {
    margin-bottom: $default-margin;
}

#main,
#newsletter {
    .mod_article {
        margin-bottom: $default-margin * 2;

        &.first {
            margin-bottom: 0;
        }

        &.bg-primary {
            padding-top: $default-margin * 2;
            padding-bottom: $default-margin;

            @include media-breakpoint-down(lg) {
                padding-top: $default-margin;
                padding-bottom: $default-margin;
            }

            margin-bottom: 0;

            h1,
            h2 {
                &:first-of-type {
                    margin-top: 0;
                }
            }

            a {
                &:before {
                    color: #fff;
                }

                &:hover {
                    &:before {
                        color: $body-color;
                    }
                }
            }
        }

        &.bg-secondary {
            font-size: 2.8rem;
            line-height: 4rem;

            margin-bottom: 0;
            padding-top: $default-margin * 2;
            padding-bottom: $default-margin * 2;

            @include media-breakpoint-down(lg) {
                padding-top: $default-margin;
                padding-bottom: $default-margin;
            }

            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            color: #fff;

            p {
                margin: 0;

                &:last-of-type {
                    font-family: $font-family-serif;
                    font-weight: bold;

                    color: map-get($map: $theme-colors, $key: "primary");
                }

                a {
                    color: map-get($map: $theme-colors, $key: "primary");
                    text-decoration: none !important;
                    font-size: inherit !important;
                    font-family: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        &.bg-light-gray {
            padding-top: $default-margin * 2;
            padding-bottom: $default-margin;

            @include media-breakpoint-down(lg) {
                padding-top: $default-margin;
                padding-bottom: $default-margin;
            }

            margin-bottom: 0;

            h1,
            h2 {
                &:first-of-type {
                    margin-top: 0;
                }
            }

            .ce_text {
                margin-bottom: $default-margin * 2;

                @include media-breakpoint-down(lg) {
                    margin-bottom: $default-margin;
                }

                .inner {
                    background: #fff;

                    h3,
                    p {
                        padding-left: 17px;
                        padding-right: 17px;
                    }

                    padding-bottom: 17px;
                }
            }
        }

        &.teaser {
            h2 {
                margin-top: 0;
                text-align: left;
                margin-bottom: $default-margin;
                font-size: 1.8rem;
                line-height: $default-margin;
            }

            .ce_text.text-right {
                h2 {
                    text-align: right;
                }
            }
        }
    }

    .ce_hyperlink {
        a.btn {
            margin-top: $default-margin;
            width: 100%;
        }
    }

    a:not(.headline) {
        font-size: 1.4rem;
        font-weight: bold;

        text-transform: uppercase;

        color: $body-color;

        &:before {
            font-size: 1.8rem;

            content: "»";
            margin-right: 3px;

            color: map-get($map: $theme-colors, $key: "primary");
        }

        &:hover {
            &:before {
                color: $body-color;
            }
        }
    }

    .ce_text {
        ul {
            margin: 0 0 $default-margin 0;
            padding: 0 0 0 15px;

            list-style: none;

            li {
                &:before {
                    position: relative;
                    bottom: 3px;

                    margin: 0 10px 0 -15px;
                    padding: 0;

                    content: "-";
                }
            }
        }
    }

    .image_container {
        margin-bottom: $default-margin;

        a {
            &:before {
                display: none;
            }
        }
    }

    .box {
        p {
            margin-bottom: $default-margin / 2;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    // .row.d-flex.align-items-center {
    // *[class*=" col-"] {

    //     p,
    //     .image_container {
    //         &:last-of-type {
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    // }

    .trenner {
        .image_container,
        p {
            margin: 0;
        }

        .flex-grow-1 {
            background: map-get($map: $theme-colors, $key: "secondary");
            height: 1px;
        }

        .flex-shrink-1 {
            margin: 0 ($default-margin / 2);

            .ce_text {
                font-family: $font-family-serif;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }
    }

    ol {
        margin: 0 0 $default-margin 0;
        padding-left: $default-margin;
    }

    .mod_newsreader,
    .mod_eventreader {
        h1 {
            margin-bottom: 0;
        }

        .info {
            font-size: 1.8rem;

            margin-bottom: $default-margin;
        }

        .layout_full {
            margin-bottom: $default-margin * 2;
        }
    }
}

.btn {
    font-size: 1.6rem;
    line-height: 3.5rem;

    min-width: 195px;
    padding-right: 30px;
    padding-left: 30px;

    letter-spacing: 0.05em;

    // border-top-left-radius: 10px;
    // border-bottom-right-radius: 10px;
}

/**
News
*************************************************************/

.layout_teaser {
    margin-bottom: ($default-margin * 2);

    h2 {
        text-align: left;
        margin: 0 0 0 0;
        line-height: 2.6rem;
        font-size: 1.8rem;

        a {
            color: $body-color;
        }
    }

    .description {
        p:last-of-type {
            margin-bottom: 0;
        }
    }

    .more {
        margin-top: $default-margin;
        margin-bottom: 0;
    }
}

.trenner.last {
    display: none;
}

.pagination {
    a {
        &:before {
            display: none;
        }
    }
}

/**
Downloads
*************************************************************/

.ce_downloads {
    ul {
        margin: 0;
        padding: 0;
    }

    a {
        &:before {
            display: none;
        }
        &:hover {
            color: map-get($map: $theme-colors, $key: 'primary') !important;
        }
    }
}

/**
Cookiebar
*************************************************************/

.cookiebar {
    background-color: map-get($map: $theme-colors, $key: "secondary");

    a {
        font-size: 1.4rem;
        font-weight: bold;

        text-transform: uppercase;
        text-decoration: none;

        color: #fff;

        &:before {
            font-size: 1.8rem;

            content: "»";
            margin-right: 3px;

            color: map-get($map: $theme-colors, $key: "primary");
        }

        &:hover {
            &:before {
                color: #fff;
            }
        }
    }

    .cookiebar__button {
        background-color: map-get($map: $theme-colors, $key: "primary");
    }
}

/**
Footer
*************************************************************/

#footer {
    .mod_article.bg-primary {
        padding: ($default-margin * 2) 0;

        @include media-breakpoint-down(lg) {
            padding: $default-margin 0;
        }

        h2 {
            &:first-of-type {
                margin-top: 0;
                margin-bottom: $default-margin * 2;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
            }
        }

        #newsletter {
            form {
                margin-top: -26px;
                margin-bottom: 26px;

                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }

                .form-control {
                    border: none;
                }

                .message {
                    margin-top: 50px;
                }
            }

            a {
                &:before {
                    color: #fff;
                }

                &:hover {
                    &:before {
                        color: $body-color;
                    }
                }
            }
        }

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .mod_article.bg-secondary {
        color: #fff;
        padding: ($default-margin * 2) 0;

        @include media-breakpoint-down(lg) {
            padding: $default-margin 0;
        }

        margin-bottom: 0;

        b,
        strong,
        li,
        a {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 2.6rem;
            letter-spacing: 0.1em;

            text-transform: uppercase;
            font-family: $font-family-serif;
        }

        p {
            line-height: 2.6rem;
        }

        ul,
        li {
            padding: 0;
            margin: 0;

            list-style-type: none;

            a {
                color: #fff;

                &::before {
                    display: none;
                }

                &:hover {
                    color: map-get($map: $theme-colors, $key: "primary");
                }
            }

            &.active {
                color: map-get($map: $theme-colors, $key: "primary");
            }
        }

        *[class*=" col-"] {
            @include media-breakpoint-down(lg) {
                margin-bottom: $default-margin;
            }

            p,
            li {
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
